import { isNativeMobile } from '@vestwell-frontend/helpers';

import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { useCallback, useEffect, useState } from 'react';

export function useSecureStorage(
  key: string
): [string | null, (value: string | null) => Promise<void>, boolean] {
  const [secureStorageValue, setSecureStorageValue] = useState<string | null>(
    null
  );
  const [isMobileApp, setIsMobileApp] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const setSecureStorageValueInStorage = useCallback(
    async (value: string | null) => {
      try {
        await SecureStoragePlugin.set({ key, value });
        setSecureStorageValue(value);
      } catch (e) {
        console.warn('Failed to set secure storage value', e);
      }
    },
    [key]
  );

  const getSecureStorageValue = useCallback(async () => {
    if (!isMobileApp) return;
    try {
      const result = await SecureStoragePlugin.get({ key });
      setSecureStorageValue(result?.value || null);
      setIsLoading(false);
    } catch (e) {
      console.warn('Failed to get secure storage value', e);
      setIsLoading(false);
    }
  }, [isMobileApp, key]);

  useEffect(() => {
    (async () => {
      const isNative = await isNativeMobile();
      setIsMobileApp(isNative);
    })();
  }, []);

  useEffect(() => {
    getSecureStorageValue();
  }, [getSecureStorageValue]);

  return [secureStorageValue, setSecureStorageValueInStorage, isLoading];
}
