import { FileOpener } from '@capacitor-community/file-opener';
import { Directory, Filesystem } from '@capacitor/filesystem';

import Cookies from 'js-cookie';
import { MouseEvent, useCallback, useState } from 'react';

import { useNotifications } from './useNotifications';
import { useSecureStorage } from './useSecureStorage';

const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const useDownload = (pathname: string) => {
  const [data, setData] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileToken] = useSecureStorage('token');
  const [mobileBaseUrl] = useSecureStorage('BASE_API_URL');

  const notify = useNotifications();

  return useCallback(
    async (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();

      // If already loading or data exists, prevent further action
      if (isLoading) {
        notify.info('Document is already being downloaded.');
        return;
      }

      if (data) {
        if (!mobileToken) {
          window.open(data, '_blank'); // For web, open existing document
        } else {
          try {
            // For mobile, re-open the file if already downloaded
            await FileOpener.open({
              filePath: data,
              openWithDefault: true
            });
          } catch (error) {
            console.error('Error re-opening file on mobile', error);
            notify.error('Error re-opening file');
          }
        }
        return;
      }

      let token;
      const webToken = Cookies.get('token');
      if (webToken) {
        token = webToken;
      } else if (mobileToken) {
        const parsedToken = JSON.parse(mobileToken);
        if (parsedToken?.access_token && parsedToken?.token_type) {
          token = `${parsedToken.token_type} ${parsedToken.access_token}`;
        }
      }

      notify.info(
        'The document is being prepared for download. This may take a few seconds.'
      );
      setIsLoading(true);

      try {
        const url = mobileToken ? `${mobileBaseUrl}${pathname}` : pathname; // Only append base URL for mobile

        const response = await fetch(url, {
          headers: {
            Authorization: token
          }
        });

        const blob = await response.blob();

        if (!mobileToken) {
          // Web: Open the blob as a new URL
          const fileUrl = window.URL.createObjectURL(blob);
          setData(fileUrl);
          window.open(fileUrl, '_blank'); // open in new tab
        } else {
          // Mobile: Save file and open with default app
          const now = new Date().getTime();
          const fileExtension = blob.type.split('/')[1];
          const fileContent = await blobToBase64(blob);

          const filePath = `${now}.${fileExtension}`;
          const savedFile = await Filesystem.writeFile({
            data: fileContent,
            directory: Directory.Documents,
            path: filePath,
            recursive: true
          });

          setData(savedFile.uri); // Store file URI for reuse

          await FileOpener.open({
            filePath: savedFile.uri,
            openWithDefault: true
          });
        }
      } catch (error) {
        console.error('Failed to fetch document', error);
        notify.error('Failed to fetch document');
      } finally {
        setIsLoading(false);
      }
    },
    [data, isLoading, pathname, mobileToken]
  );
};
