import isPropValid from '@emotion/is-prop-valid';
import { Box, Container, styled } from '@mui/material';
import type { HttpErrorType } from '@vestwell-frontend/hooks';

import {
  Children,
  CSSProperties,
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  isValidElement,
  ReactElement,
  ReactNode,
  useMemo
} from 'react';

import { Loader } from './Loader';
import { PageContext } from './PageContext';
import { PageFooter, PageFooterProps } from './PageFooter';
import { StatusCodeError } from './StatusCodeError';

const Content = styled(Container, {
  shouldForwardProp: prop =>
    !['innerStyles', 'hasStickyFooter'].includes(prop.toString())
})<{ innerStyles?: CSSProperties; hasStickyFooter?: boolean }>(
  ({ innerStyles, hasStickyFooter, theme }) => ({
    ...(hasStickyFooter && {
      marginBottom: theme.spacing(16)
    }),
    ...innerStyles
  })
);

/**
 * Page
 * Scrollable, stretched layout for content
 */
export type PageProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  'data-component'?: string;
  children: ReactNode;
  /** when truthy it displays the error message */
  error?: Pick<HttpErrorType, 'code' | 'status'>;
  /** Slot to render footer within layout */
  footer?: ReactNode;
  /** adds extra padding in mobile view for drawer button (padding-left) */
  hasDrawer?: boolean;
  /** adds extra padding in mobile view for shelf button (padding-right) */
  hasShelf?: boolean;
  /** add css styles to the nested div */
  innerStyles?: CSSProperties;
  /** display the loading indicator */
  isLoading?: boolean;
  /** temporary workaround for portals with corrected page structure */
  noBottomPadding?: boolean;
};

const StyledPage = styled('div', {
  shouldForwardProp: isPropValid
})<{
  hasDrawer?: boolean;
  hasShelf?: boolean;
  hasStickyFooter?: boolean;
}>(props => ({
  '& + [data-component="pageFooter"]': {
    boxShadow: '1px -3px 5px #72727226',
    paddingLeft: props.theme.spacing(12),
    paddingRight: props.theme.spacing(props.hasShelf ? 6 : 12),
    [props.theme.breakpoints.down('lg')]: {
      paddingLeft: props.theme.spacing(10),
      paddingRight: props.theme.spacing(10)
    },
    [props.theme.breakpoints.down('sm')]: {
      paddingLeft: props.theme.spacing(props.hasShelf ? 10 : 4),
      paddingRight: props.theme.spacing(props.hasShelf ? 10 : 4)
    }
  },
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gridArea: 'page',
  height: '100%',
  overflowY: 'auto',
  paddingLeft: props.theme.spacing(12),
  paddingRight: props.theme.spacing(props.hasShelf ? 6 : 12),
  paddingTop: props.theme.spacing(8),
  position: 'relative',
  width: '100%',
  [props.theme.breakpoints.down('sm')]: {
    padding: props.theme.spacing(
      84 / 4,
      props.hasShelf ? 10 : 4,
      68 / 4,
      props.hasDrawer ? 10 : 4
    ),
    width: '100%'
  },
  ...(props.hasStickyFooter && {
    paddingBottom: '0 !important'
  })
}));

const hasFooter = (
  element: ReactNode
): element is ReactElement<PageFooterProps> =>
  isValidElement<PageFooterProps>(element) && element.type === PageFooter;

export const Page = forwardRef<HTMLDivElement, PageProps>(
  (
    {
      children,
      className,
      error,
      footer,
      innerStyles,
      isLoading,
      noBottomPadding,
      ...props
    },
    ref
  ) => {
    const ctx = useMemo(
      () => ({
        hasMainSiblings: Children.count(children) > 1
      }),
      [children]
    );
    const hasStickyFooter = useMemo(
      () => hasFooter(footer) && footer.props.sticky,
      [footer]
    );

    return (
      <PageContext.Provider value={ctx}>
        <StyledPage
          {...props}
          data-component={props['data-component'] || 'Page'}
          hasStickyFooter={hasStickyFooter}
          id='mainContent'
          ref={ref}>
          <Content
            hasStickyFooter={hasStickyFooter}
            innerStyles={innerStyles}
            //https://stackoverflow.com/questions/45519275/grid-in-material-ui-causes-horizontal-scroll-react
            maxWidth={false}>
            {isLoading && <Loader />}
            {error && (
              <Box
                alignItems='center'
                bottom={0}
                display='flex'
                height='100%'
                justifyContent='center'
                left={0}
                position='absolute'
                right={0}
                top={0}
                width='100%'>
                <StatusCodeError code={error.code} status={error.status} />
              </Box>
            )}
            {!isLoading && children}
          </Content>
          {!isLoading && footer}
        </StyledPage>
      </PageContext.Provider>
    );
  }
);

Page.displayName = 'Page';
